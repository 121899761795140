


































import Vue from 'vue';
import API from '@/plugins/axios';
import { Questionnaire, Test } from '@/types/types';
import QuestionnaireIcon from '../../../../components/QuestionnaireIcon.vue';
import { roles, ROLES_ANSWERING_PSY2 } from '@/utils/utils';

export default Vue.extend({
  components: { QuestionnaireIcon },
  name: 'PersonalTab',
  props: ['id'],
  data: () => ({
    activity: {} as Record<string, { start_date: string; end_date: string }>,
    ROLES_ANSWERING_PSY2,
  }),
  computed: {
    tests(): Test[] {
      // ToDo temporary removed filter
      // return this.$store.getters.getTests.filter((t: Test) => t.evaluation[0]);
      const data = this.$store.getters.getTests;
      return data.filter(
        (t: Test, i: number) =>
          t.evaluation[0] || (t.title == 'Trail 2' && i > 1 && (data[i - 1].evaluation[0] || data[i - 2].evaluation[0]))
      );
    },
    role() {
      return this.$store.getters.activeTenantRole;
    },
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  methods: {
    async openEvaluation(test: Test) {
      if (test.is_ps2) {
        if (!ROLES_ANSWERING_PSY2.includes(this.role)) return;
        let evaluation_id;
        if (!test.evaluation.length) {
          // 3
          const { data } = await API.post(`v1/prosys/councillor/evaluations/${this.$props.id}/${test.id}/`);
          evaluation_id = data.id;
        } else {
          evaluation_id = test.evaluation[0].id;
        }
        this.$router.push({ name: 'Psychometrics', params: { id: evaluation_id, user_id: this.$props.id } });
      } else if (test.evaluation[0]?.is_completed) {
        this.$router.push({
          name: 'QuestionnairePreview',
          params: { id: String(test.evaluation[0].id), user_id: this.$props.id },
        });
      }
    },
    icon(questionnaire: Questionnaire) {
      if (!questionnaire.evaluation.length) {
        return 'close';
      }
      if (questionnaire.evaluation[0].is_completed) {
        return 'finished';
      }
      return 'in_progress';
    },
  },
  async mounted() {
    const { id } = this.$props;
    try {
      // 3
      const { data } = await API.get(`v1/prosys/councillor/students/${id}/`);
      const tests = data.map((test: Test) => {
        if (test.evaluation.length) {
          return {
            ...test,
            created_at: this.$date(test.created_at).format('DD-MM-YYYY'),
            evaluation: test.evaluation.map(e => ({
              ...e,
              created_at: this.$date(e.created_at).format('DD-MM-YYYY'),
            })),
          };
        }
        return {
          ...test,
          created_at: this.$date(test.created_at).format('DD-MM-YYYY'),
        };
      });
      this.$store.dispatch('setTests', tests);
    } catch (e) {
      console.error(e);
    }

    if (this.role === roles.ADMIN) {
      try {
        // 4
        const { data: templates } = await API.get<{ templates: Questionnaire[]; activate_templates: any[] }>(
          `v1/prosys/councillor/templates/`
        );
        const activities: Record<string, { start_date: string; end_date: string }> = {};
        for (const activity of templates.activate_templates) {
          activities[activity.questioner] = activity;
        }
        this.activity = activities;
      } catch (e) {
        console.error(e);
      }
    }
  },
});
