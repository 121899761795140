


























import Vue from 'vue';
import { roles } from '@/utils/utils';

export default Vue.extend({
  name: 'LayoutSidebar',
  data() {
    return {
      roles,
    };
  },
  computed: {
    role() {
      return this.$store.getters.activeTenantRole;
    },
    bgColor(): string {
      if (!this.$store.state.User.settings.common) return 'default';
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
});
