


























































































































































import Vue from 'vue';
import API from '../../plugins/axios';
import { Questionnaire } from '../../types/types';

export default Vue.extend({
  components: {},
  name: 'Questionnaries',
  data() {
    return {
      dialog: false,
      startDate: null,
      endDate: null,
      schoolYear: '',
      templates: [] as Questionnaire[],
      activity: [] as any[],
      headers: [
        { text: 'School Year', value: 'school_year', sortable: false },
        { text: 'Start Date', value: 'start_date', sortable: false },
        { text: 'End Date', value: 'end_date', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      isFormValid: false,
      loading: false,
      activatingTemplate: false,
      deleteDialog: false,
      activityForDelete: null as number | null,
      deletingActivity: false,
    };
  },
  computed: {
    startDateFormatted() {
      if (!this.startDate) return null;
      return (this as any).formatDate((this as any).startDate);
    },
    endDateFormatted() {
      if (!this.endDate) return null;
      return (this as any).formatDate((this as any).endDate);
    },
    translatedHeaders() {
      return (this as any).headers.map((h: any) => ({ ...h, text: this.$t(`Questionnaires.TableColumns.${h.value}`) }));
    },
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  methods: {
    formatDate(date: string) {
      const [y, m, d] = date.split('-');
      return `${d}/${m}/${y}`;
    },
    allowedStartDates(val: string) {
      if (!this.endDate) return true;
      return (this as any).$date(val).isBefore(this.endDate);
    },
    allowedEndDates(val: string) {
      if (!this.startDate) return true;
      return (this as any).$date(val).isAfter(this.startDate);
    },
    clearDialog() {
      this.dialog = false;
      this.startDate = null;
      this.endDate = null;
    },
    async makeActive() {
      this.activatingTemplate = true;
      // 4
      await API.post('v1/prosys/councillor/templates/', {
        start_date: this.startDate,
        end_date: this.endDate,
        school_year: this.schoolYear,
      });
      await this.getActivity();
      this.activatingTemplate = false;
      this.clearDialog();
    },
    isActive(activity: { start_date: string; end_date: string }) {
      const start_date = (this as any).$date(activity.start_date);
      const end_date = (this as any).$date(activity.end_date);
      const today = (this as any).$date();
      return today.isBetween(start_date, end_date);
    },
    status(activity: { start_date: string; end_date: string }) {
      const end_date = (this as any).$date(activity.end_date);
      const start_date = (this as any).$date(activity.start_date);
      const today = (this as any).$date();
      if (today.isBefore(start_date)) return 'Scheduled';
      if (today.isAfter(start_date) && today.isBefore(end_date)) return 'Active';
      if (today.isAfter(end_date)) return 'Passed';
      return '';
    },
    async handleDelete() {
      this.deletingActivity = true;
      try {
        // 4
        await API.delete(`v1/prosys/councillor/template/${this.activityForDelete}/`);
        this.activity = this.activity.filter(a => a.id !== this.activityForDelete);
        this.activityForDelete = null;
        this.deleteDialog = false;
      } catch (e) {
        console.error(e);
      } finally {
        this.deletingActivity = false;
      }
    },
    openDeleteDialog({ id }: any) {
      this.activityForDelete = id;
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.activityForDelete = null;
      this.deleteDialog = false;
    },
    async getActivity() {
      this.loading = true;
      // 4
      const { data } = await API.get<{ templates: Questionnaire[]; activate_templates: any[] }>(
        `v1/prosys/councillor/templates/`
      );
      this.templates = data.templates;
      this.activity = data.activate_templates
        .sort((a, b) => {
          const statusA = this.status(a);
          const statusB = this.status(b);
          if (statusA === 'Active' && statusB === 'Active') {
            const schoolYearComparisonResult = a.school_year.localeCompare(b.school_year);
            if (schoolYearComparisonResult === 0) {
              return this.$date().diff(a.start_date) - this.$date().diff(b.start_date);
            } else {
              return schoolYearComparisonResult;
            }
          } else if (statusA === 'Active') {
            return -1;
          } else if (statusB === 'Active') {
            return 1;
          } else if (statusA === 'Scheduled' && statusB === 'Scheduled') {
            return 0;
          } else if (statusA === 'Scheduled') {
            return -1;
          } else if (statusB === 'Scheduled') {
            return 1;
          } else {
            return this.$date().diff(a.start_date) - this.$date().diff(b.start_date);
          }
        })
        .map(a => ({
          ...a,
          start_date: this.$date(a.start_date).format('DD/MM/YY'),
          end_date: this.$date(a.end_date).format('DD/MM/YY'),
          status: this.status(a),
        }));
      this.loading = false;
    },
    rules() {
      return {
        required: (v: string) => !!v || 'Required',
        schoolYearFormat: (v: string) => Boolean(v.match(/^\d{4} - \d{4}$/)) || 'Incorrect format',
      };
    },
  },
  async mounted() {
    await this.getActivity();
  },
});
