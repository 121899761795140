

































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import { Question, ReorderData } from '../../../types/types';
import API from '@/plugins/axios';

export default Vue.extend({
  name: 'LayoutSidebar',
  components: {
    draggable,
  },
  data() {
    return {
      activePredictor: false as boolean | number,
      newQuestionDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      questions: 'getQBQuestions',
      predictors: 'getQBPredictors',
      activeQuestion: 'getActiveQBQuestion',
    }),
    questionsByPredictor() {
      const questionsByPredictor: { [key: string]: Question[] } = {};
      (this.questions as Question[]).forEach(question => {
        if (!questionsByPredictor[question.predictor_name]) {
          questionsByPredictor[question.predictor_name] = [];
        }
        questionsByPredictor[question.predictor_name].push(question);
      });
      for (const predictor in questionsByPredictor) {
        questionsByPredictor[predictor].sort((a, b) => a.order - b.order);
      }
      return questionsByPredictor;
    },
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  methods: {
    async itemReordered(evt: ReorderData) {
      this.$store.dispatch('reorderItem', evt);
      try {
        // 4
        await API.patch(`v1/prosys/councillor/question_builder/reorder_template_question/`, {
          id: evt.moved.element.id,
          order: evt.moved.newIndex,
        });
      } catch {
        this.$snackbar({
          icon: 'mdi-alert',
          text: this.$t('QuestionnaireBuilder.reorderingError'),
          type: 'error',
        });
        const { id } = this.$route.params;
        this.$store.dispatch('getQBQuestions', id);
      }
    },
    changeActive(active: boolean, item_id: number) {
      this.activePredictor = active ? item_id : false;
    },
    activateQuestion(question_id: number | 'new_multiple_choice' | 'new_open_ended', predictor_id?: number) {
      this.newQuestionDialog = false;
      this.$store.dispatch('setActiveQBQuestion', { question_id, predictor_id });
    },
    isActiveQuestion(id: number) {
      return this.activeQuestion?.id == id;
    },
    deleteQuestion(question_id: number) {
      const { id } = this.$route.params;
      this.$store.dispatch('deleteQBQuestion', { question_id, questionnaire_id: id });
    },
  },
});
