import { DataTableHeader } from 'vuetify';
import { StudentResults } from '@/types/types';
import { v4 as uuid } from 'uuid';

// export const displayOptions = ['Overall', 'Construct', 'Predictor', 'Questionnaire', 'Estimated'];
// export const displayOptions = ['Questionnaire'];

export const displayColumnsDemographics = {
  // _class: true,
  gender: false,
  age: false,
  school_year: true,
  disability: false,
  employed: false,
  education_level: false,
  lives_with_parents: false,
  dependants: false,
  income: false,
  mother_education: false,
  father_education: false,
  household_income: false,
};

export const displayColumnsPsychometrics = {
  predictors: false,
  questionnaires: false,
  psychometric_risk: true,
  estimated_risk: true,
};

export const allColumns: DataTableHeader[] = [
  // DEMOGRAPHICS
  {
    text: 'Name',
    value: 'name',
    sortable: true,
    width: '320px',
  },
  {
    text: 'Class',
    value: '_class',
    sortable: true,
  },
  {
    text: 'Gender',
    value: 'gender',
    sortable: true,
  },
  {
    text: 'Age',
    value: 'age',
    sortable: true,
  },
  {
    text: 'School year',
    value: 'school_year',
    sortable: true,
  },
  {
    text: 'Disability',
    value: 'disability',
    sortable: true,
  },
  {
    text: 'Employed',
    value: 'employed',
    sortable: true,
  },
  {
    text: 'Education level',
    value: 'education_level',
    sortable: true,
  },
  {
    text: 'Live with parents',
    value: 'lives_with_parents',
    sortable: true,
  },
  {
    text: 'People depending',
    value: 'dependants',
    sortable: true,
  },
  {
    text: 'Personal income',
    value: 'income',
    sortable: true,
  },
  {
    text: "Mother's education level",
    value: 'mother_education',
    sortable: true,
  },
  {
    text: "Father's education level",
    value: 'father_education',
    sortable: true,
  },
  {
    text: 'Household income',
    value: 'household_income',
    sortable: true,
  },
  // PSYCHOMETRICS
  {
    text: 'Predictors',
    value: 'predictors',
  },
  {
    text: 'Questionnaires',
    value: 'questionnaires',
  },
  {
    text: 'Psychometrics risk',
    value: 'psychometric_risk',
  },
  {
    text: 'Estimated risk',
    value: 'estimated_risk',
  },
];

export const psychometricsColumns = {
  predictors: [
    {
      text: 'Predictor title',
      value: 'predictor_title',
      sortable: false,
      width: '250px',
    },
    {
      text: 'Predictor risk',
      value: 'predictor_risk',
      sortable: false,
      width: '100px',
    },
    {
      text: 'Predictor score',
      value: 'predictor_score',
      sortable: false,
      width: '100px',
    },
    {
      text: 'Questionnaire title',
      value: 'questionnaire_title',
      sortable: false,
      width: '250px',
    },
  ],
  questionnaires: [
    {
      text: 'Questionnaire title',
      value: 'questionnaire_title',
      sortable: false,
      width: '250px',
    },
    {
      text: 'Questionnaire risk',
      value: 'questionnaire_risk',
      sortable: false,
      width: '100px',
    },
    {
      text: 'Questionnaire score',
      value: 'questionnaire_score',
      sortable: false,
      width: '100px',
    },
  ],
  psychometric_risk: [
    {
      text: 'Psychometrics risk',
      value: 'psychometric_risk',
      sortable: false,
      width: '100px',
    },
    {
      text: 'Psychometrics score',
      value: 'psychometric_score',
      sortable: false,
      width: '100px',
    },
  ],
  estimated_risk: [
    {
      text: 'Estimated risk',
      value: 'estimated_risk',
      sortable: false,
      width: '100px',
    },
    {
      text: 'Note',
      value: 'note',
      sortable: false,
      width: '100px',
    },
  ],
};

export const studentResultTabColumn: DataTableHeader[] = [
  ...([allColumns.find(c => c.value === 'school_year'), allColumns.find(c => c.value === '_class')].map(c => ({
    ...c,
    class: 'h-demographic',
  })) as DataTableHeader[]),
  ...psychometricsColumns.questionnaires.map(c => ({ ...c, class: 'h-questionnaire' })),
  ...psychometricsColumns.psychometric_risk.map(c => ({ ...c, class: 'h-psychometrics' })),
  ...psychometricsColumns.estimated_risk.map(c => ({ ...c, class: 'h-other' })),
];

export const overallStudents = (students: StudentResults[]) => {
  return students.map(student => {
    return {
      uuid: uuid(),
      id: student.user_id,
      name: student.name,
      _class: student._class,
      gender: student.gender,
      age: student.age,
      school_year: student.school_year,
      disability: student.disability ? 'Yes' : 'No',
      employed: student.employed ? 'Yes' : 'No',
      education_level: student.education_level,
      lives_with_parents: student.lives_with_parents ? 'Yes' : 'No',
      dependants: student.dependants ? 'Yes' : 'No',
      income: student.income,
      mother_education: student.mother_education,
      father_education: student.father_education,
      household_income: student.household_income,
      questionnaire_title: student.questionnaire_title,
      questionnaire_risk: student.questionnaire_risk ? 'Yes' : 'No',
      questionnaire_score: student.questionnaire_score,
      predictor_title: student.predictor_title,
      predictor_risk: student.predictor_risk == 'NONE' ? 'None' : student.predictor_risk == 'TRUE' ? 'Yes' : 'No',
      predictor_score: student.predictor_score,
      psychometric_risk: student.psychometric_risk,
      psychometric_score: student.psychometric_score,
      estimated_risk: student.estimated_risk,
      note: student.note_id,
    };
  });
};

export const ageFilter = {
  '<=15': '0-15',
  '16-18': '16-18',
  '19-21': '19-21',
  '22-24': '22-24',
  '25-50': '25-50',
  '>50': '50-200',
};

export const riskFilter = {
  None: 'None',
  'No to Low Risk': 'LOW',
  'Medium Risk': 'MEDIUM',
  'High Risk': 'HIGH',
};

export const riskLevelColor = {
  HIGH: 'error',
  MEDIUM: 'warning',
  LOW: 'success',
};

export const eduLevelShorten = {
  'No formal school-leaving qualification': 'No school',
  "I don't know": 'Unknown',
  'School leaving Certificate': 'Certificate',
  'MQF Level 3 or Ordinary Levels': 'MQF-3',
  'MQF Level 4 or Advanced Levels': 'MQF-4',
  'MQF Level 5': 'MQF-5',
  'MQF Level 6 (Bachelor Degree)': 'MQF-6',
  'MQF Level 7 (Post-Graduate/Masters Degree)': 'MQF-7',
  'MQF Level 8 (Doctorate Degree)': 'MQF-8',
  'Prefer not to say': 'Prefer not to say',
};
