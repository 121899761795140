







import Vue from 'vue';
export default Vue.extend({
  name: 'QuestionnarieIcon',
  props: ['start_date', 'end_date', 'active', 'i'],
  computed: {
    bgColor() {
      if ((this as any).$props.active) {
        return 'success';
      }
      const start_date = (this as any).$date(this.$props.start_date);
      const end_date = (this as any).$date(this.$props.end_date);
      const today = (this as any).$date();
      const icon = (this as any).$props.i;
      if (icon == 'in_progress') return 'in-progress';
      const bg = today.isBetween(start_date, end_date) ? 'success' : 'lock';
      if (bg == 'lock' && icon == 'finished') return 'mid-lock';
      return bg;
    },
    icon() {
      const icon = (this as any).$props.i;
      const icons: Record<string, string> = {
        finished: 'mdi-check-all',
        locked: 'mdi-lock-outline',
        close: 'mdi-close',
        in_progress: 'mdi-timer-sand-full',
      };
      return icon ? icons[icon] : '';
    },
  },
});
