




















































import Vue from 'vue';
import API from '@/plugins/axios';
import { FieldTypes, Test } from '@/types/types';

type Field = {
  id: number;
  label: string;
  type: string;
  text_value: string;
  maltese_text_value: string;
  answer_fields: { id: number; text_value?: string; value: number }[];
};

export default Vue.extend({
  data() {
    return {
      questions: [],
    };
  },
  async mounted() {
    // 3
    const { data } = await API.get(
      `v1/prosys/councillor/questions/${this.$route.params.user_id}/${this.$route.params.id}/`
    );
    this.questions = data;
  },
  computed: {
    questionnaire(): Test {
      return this.$store.getters.getTests.find((test: Test) => test.evaluation?.[0]?.id === +this.$route.params.id);
    },
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  methods: {
    getFieldAnswer(field: Field) {
      let value;
      if (field.type === FieldTypes.Radio) {
        value = JSON.parse(this.$i18n.locale === 'mt' ? field.maltese_text_value : field.text_value)[
          field.answer_fields[0].value
        ];
      } else if (field.type === FieldTypes.Text || field.type === FieldTypes.TextArea) {
        value = field.answer_fields[0]?.text_value;
      } else if (field.type === FieldTypes.ScaleText) {
        const textValue = JSON.parse(field.text_value)[0];
        if (textValue[field.answer_fields[0].value]) value = textValue[field.answer_fields[0].value];
        else value = `${field.answer_fields[0].value} (${this.getRange(textValue)})`;
      } else if (field.type === FieldTypes.Scale) {
        value = field.answer_fields[0].value;
      }
      return value;
    },
    getRange(textValue: { [key: number]: string }) {
      const keys = Object.keys(textValue).map(Number);
      const minKey = Math.min(...keys);
      const maxKey = Math.max(...keys);
      return `Range ${minKey} ${textValue[minKey]} - ${maxKey} ${textValue[maxKey]}`;
    },
    back() {
      this.$router.push(`/creator/students/${this.$route.params.user_id}`);
    },
  },
});
