var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pt-2 fill-height overflow-x-auto",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"data-table",class:( _obj = {}, _obj[("bg-" + _vm.bgColor + "-light")] = !_vm.$vuetify.theme.dark && _vm.bgColor, _obj ),attrs:{"headers":_vm.translatedHeaders,"items":_vm.computedTableItems,"loading":_vm.loading,"server-items-length":_vm.itemsCount,"options":_vm.options,"items-per-page":25,"footer-props":{
      'items-per-page-options': [25],
      'disable-items-per-page': true,
    },"item-key":"uuid"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.note",fn:function(ref){
    var item = ref.item;
return [(item.note)?_c('v-btn',{attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){_vm.getNote(item.note);
          _vm.dialog = true;}}},[_vm._v(_vm._s(_vm.$t('Students.viewNote')))]):_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.note)?_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-1 pa-3"},[_vm._v(" "+_vm._s(_vm.$t('note'))+" ")]),_c('v-divider'),_c('div',{staticClass:"pa-3 pb-6"},[_c('event-details',{attrs:{"event":_vm.note}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }