




















































import Vue from 'vue';
import API from '@/plugins/axios';
import { formatFileSize, ROLES_CREATE_EDIT_NOTES } from '@/utils/utils';
import { riskLevelColor } from '@/utils/studentTable';

export default Vue.extend({
  props: ['event', 'editable'],
  data() {
    return {
      eventData: { ...this.event },
      riskLevelColor,
      ROLES_CREATE_EDIT_NOTES,
    };
  },
  mounted() {
    this.fetchFileData();
  },
  methods: {
    async fetchFileData() {
      if (this.eventData.media_file_uuid && !this.eventData.file_data) {
        try {
          const { data } = await API.get(`v1/files/media-file/${this.eventData.media_file_uuid}/`);
          this.eventData = {
            ...this.eventData,
            file_data: data,
          };
        } catch (e) {
          console.error(e);
        }
      }
    },
    formatFileSize,
  },
  watch: {
    event(newEvent) {
      this.eventData = { ...newEvent };
      this.fetchFileData();
    },
  },
  computed: {
    role() {
      return this.$store.getters.activeTenantRole;
    },
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
});
