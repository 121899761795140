

















































































































































































































































import Vue from 'vue';
import API from '@/plugins/axios';
import { Event, EventFileData } from '@/types/types';
import { formatFileSize, ROLES_CREATE_EDIT_NOTES } from '@/utils/utils';
import EventDetails from '@/components/EventDetails.vue';

export default Vue.extend({
  components: { EventDetails },
  name: 'EventsTab',
  props: ['id'],
  data: () => ({
    selectedItemId: null,
    searchTerm: '',
    mode: 'default' as 'default' | 'edit' | 'create',
    eventType: '',
    eventTypes: ['Custom', 'Estimated Risk'],
    riskFactors: ['LOW', 'MEDIUM', 'HIGH'],
    titleErrorMessages: [] as string[],
    descriptionErrorMessages: [] as string[],
    dataForEditOrCreate: null as null | {
      type?: string;
      title?: string;
      description?: string;
      riskFactor?: string;
      media_file_uuid?: string;
      file_data?: EventFileData;
    },
    events: [] as Event[],
    attachedFile: null as File | null,
    attachedFileName: '',
    progress: 0,
    ROLES_CREATE_EDIT_NOTES,
  }),
  computed: {
    computedEvents() {
      return this.events.filter(event => event.title.toLowerCase().includes((this.searchTerm || '').toLowerCase()));
    },
    selectedItem() {
      return this.events.find(event => event.id === this.selectedItemId);
    },
    translatedEventTypes() {
      return this.eventTypes.map(et => this.$t(`StudentProfile.Events.EventTypes.${et}`));
    },
    riskFactorLabels() {
      return {
        LOW: this.$t('StudentProfile.Events.noToLowRisk'),
        MEDIUM: this.$t('StudentProfile.Events.mediumRisk'),
        HIGH: this.$t('StudentProfile.Events.highRisk'),
      };
    },
    role() {
      return this.$store.getters.activeTenantRole;
    },
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  methods: {
    setMode(mode: 'default' | 'edit' | 'create') {
      if (mode === 'default') {
        this.dataForEditOrCreate = null;
      } else if (mode === 'edit') {
        this.dataForEditOrCreate = {
          type: this.selectedItem?.type,
          title: this.selectedItem?.title,
          description: this.selectedItem?.description,
          riskFactor: this.selectedItem?.risk_level,
          media_file_uuid: this.selectedItem?.media_file_uuid,
          file_data: this.selectedItem?.file_data,
        };
      } else {
        this.selectedItemId = null;
        this.dataForEditOrCreate = {
          title: '',
          description: '',
          riskFactor: '',
        };
      }
      this.eventType = '';
      this.mode = mode;
    },
    async handleEdit(id: number) {
      if (!this.dataForEditOrCreate) return;

      const { type, title, description, riskFactor, media_file_uuid, file_data } = this.dataForEditOrCreate;

      let fileData = media_file_uuid ? file_data : undefined;
      if (media_file_uuid && media_file_uuid !== file_data?.media_file_url) {
        const response = await API.get(`v1/files/media-file/${media_file_uuid}/`);
        fileData = response.data;
      }

      try {
        const payload = { title, description, media_file_uuid } as { [key: string]: string | undefined | null };
        if (type === 'RISK') payload.risk_level = riskFactor;
        payload.media_file_uuid = media_file_uuid || null;
        // 2
        const { data } = await API.patch(`v1/prosys/councillor/students/notes/${id}/`, payload);
        this.events = this.events.map(event => {
          if (event.id === id) {
            return {
              ...event,
              title: data.title,
              description: data.description,
              updated_at: data.updated_at,
              risk_level: data.risk_level,
              media_file_uuid,
              file_data: fileData,
            };
          }
          return event;
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.setMode('default');
        this.attachedFile = null;
        this.attachedFileName = '';
        this.progress = 0;
      }
    },
    async handleCreate() {
      if (!this.dataForEditOrCreate) return;

      const { title, description, riskFactor } = this.dataForEditOrCreate;

      if (this.eventType === this.$t('StudentProfile.Events.EventTypes.Custom') && !title)
        this.titleErrorMessages = [...this.titleErrorMessages, 'This field is required'];
      if (!description) this.descriptionErrorMessages = [...this.descriptionErrorMessages, 'This field is required'];
      if (this.titleErrorMessages.length || this.descriptionErrorMessages.length) return;

      const payload = {} as {
        type?: string;
        description?: string;
        title?: string;
        risk_level?: string;
        media_file_uuid?: string;
      };
      payload.type = this.eventType === this.$t('StudentProfile.Events.EventTypes.Custom') ? 'CUSTOM' : 'RISK';
      payload.description = description;
      if (this.eventType === this.$t('StudentProfile.Events.EventTypes.Custom')) payload.title = title;
      else {
        payload.title = 'Estimated Risk';
        payload.risk_level = (riskFactor || '').toUpperCase();
      }
      if (this.attachedFileName) {
        payload.media_file_uuid = this.attachedFileName;
      }

      try {
        // 2
        const { data } = await API.post(`v1/prosys/councillor/students/${this.$props.id}/notes/create/`, payload);
        let file_data;
        if (data.media_file_uuid) {
          const response = await API.get(`v1/files/media-file/${data.media_file_uuid}/`);
          file_data = response.data;
        }
        const event = {
          ...data,
          created_at: this.$date(data.created_at).format('DD-MM-YYYY'),
          file_data,
        };
        this.events = [event, ...this.events];
        this.selectedItemId = event.id;
      } catch (e) {
        console.error(e);
      } finally {
        this.setMode('default');
        this.attachedFile = null;
        this.attachedFileName = '';
        this.progress = 0;
      }
    },
    async handleDelete(id: number) {
      try {
        // 2
        await API.delete(`v1/prosys/councillor/students/notes/${id}/`);
        this.events = this.events.filter(event => event.id !== id);
      } catch (e) {
        console.error(e);
      } finally {
        this.setMode('default');
      }
    },
    handleCancel() {
      this.setMode('default');
      this.attachedFile = null;
      this.attachedFileName = '';
      this.progress = 0;
    },
    async handleAttach(file: File) {
      this.attachedFile = file;
      this.progress = 0;
      const form = new FormData();
      form.append('media', file);
      try {
        const { data } = await API.put('v1/files/upload/media-file/', form, {
          onUploadProgress: e => {
            this.progress = Math.round((e.loaded / e.total) * 100);
          },
        });
        this.attachedFileName = data.name;
        if (this.mode === 'edit') {
          this.dataForEditOrCreate!.media_file_uuid = data.name;
        }
      } catch (e) {
        this.attachedFile = null;
        this.attachedFileName = '';
        this.progress = 0;
        this.$snackbar({
          icon: 'mdi-alert',
          text: this.$t('Events.uploadError'),
          type: 'error',
        });
        console.error(e);
      }
    },
    handleRemoveAttachment() {
      if (this.dataForEditOrCreate) {
        this.dataForEditOrCreate.media_file_uuid = undefined;
        this.dataForEditOrCreate.file_data = undefined;
      }
    },
    removeAttachedFile() {
      this.attachedFile = null;
      this.attachedFileName = '';
      this.progress = 0;
    },
    formatFileSize,
  },
  async mounted() {
    try {
      // 2
      const { data } = await API.get(`v1/prosys/councillor/students/${this.$props.id}/notes/`);
      this.events = await Promise.all(
        data.map(async (event: Event) => {
          let file_data;
          if (event.media_file_uuid) {
            const response = await API.get(`v1/files/media-file/${event.media_file_uuid}/`);
            file_data = response.data;
          }
          return {
            ...event,
            created_at: this.$date(event.created_at).format('DD-MM-YYYY'),
            file_data,
          };
        })
      );
    } catch (e) {
      console.error(e);
    }
  },
  watch: {
    eventType() {
      this.titleErrorMessages = [];
      this.descriptionErrorMessages = [];
    },
    selectedItemId() {
      this.attachedFile = null;
      this.attachedFileName = '';
      this.progress = 0;
    },
  },
});
