import { FieldTypes } from '@/types/types';

export interface Psychometrics2Question {
  id: string;
  title: string;
  question: string;
  maltese_question: string;
  subquestion?: string;
  followup: string[];
  maltese_followup: string[];
  cues: string[];
  maltese_cues: string[];
  answers?: Answer[];
  maltese_answers?: Answer[];
  selected_answer?: number;
  answer?: string;
  field_id: number;
  field_type: string;
  predictor_name: string;
  answer_group_id: number;
  order: number;
}

interface Answer {
  value: 'Low' | 'Medium' | 'High';
  description: string[];
}

export interface Psychometrics2State {
  question: string;
  maltese_question: string;
  follow_up: string;
  maltese_follow_up: string;
  cues: string;
  maltese_cues: string;
  id: string;
  fields: Field[];
  predictor_name: string;
  answer_group_id: number;
  order: number;
}

export interface Field {
  id: number;
  text_value: string;
  maltese_text_value: string;
  question: number;
  answer_fields: [
    {
      value: number;
      text_value: string;
    }
  ];
  type: string;
}

export interface GroupAnswer {
  id: number;
  evaluation: number;
  question_group: number;
  predictor: string;
  is_populated: boolean;
  risk: boolean;
  score: number;
}

export const convertToPsychometrics2 = (ps2: Psychometrics2State): Psychometrics2Question => {
  const ps2Question: Psychometrics2Question = {
    id: ps2.id,
    title: '',
    question: ps2.question,
    maltese_question: ps2.maltese_question,
    followup: ps2.follow_up.split('\n').filter(f => f !== ''),
    maltese_followup: ps2.maltese_follow_up.split('\n').filter(f => f !== ''),
    cues: ps2.cues.split('\n').filter(c => c !== ''),
    maltese_cues: ps2.maltese_cues.split('\n').filter(c => c !== ''),
    field_id: ps2.fields[0].id,
    field_type: ps2.fields[0].type,
    predictor_name: ps2.predictor_name,
    answer_group_id: ps2.answer_group_id,
    order: ps2.order,
  };

  if (ps2.fields[0].type === FieldTypes.Radio) {
    const answers: Answer[] = [];
    const maltese_answers: Answer[] = [];
    const AnswerType = ['Low', 'Medium', 'High'] as Array<'Low' | 'Medium' | 'High'>;
    const text_values = JSON.parse(ps2.fields[0].text_value) as string[];
    const maltese_text_values = JSON.parse(ps2.fields[0].maltese_text_value) as string[];
    for (const answer_index in text_values) {
      const answer = text_values[answer_index];
      answers.push({
        value: AnswerType[answer_index],
        description: answer.split('\n'),
      });
    }
    for (const answer_index in maltese_text_values) {
      const answer = maltese_text_values[answer_index];
      maltese_answers.push({
        value: AnswerType[answer_index],
        description: answer.split('\n'),
      });
    }
    ps2Question.answers = answers;
    ps2Question.maltese_answers = maltese_answers;
    ps2Question.selected_answer = ps2.fields[0].answer_fields[0]?.value;
  } else {
    ps2Question.answer = ps2.fields[0].answer_fields[0]?.text_value;
  }

  return ps2Question;
};
