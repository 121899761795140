


















































































































































import Vue from 'vue';
import API from '@/plugins/axios';
import Events from '@/views/Employee/StudentProfile/Tabs/Events.vue';
import Personal from '@/views/Employee/StudentProfile/Tabs/Personal.vue';
import Results from '@/views/Employee/StudentProfile/Tabs/Results.vue';
import { eduLevelShorten } from '../../../utils/studentTable';
import { roles, ROLES_VIEW_NOTES } from '@/utils/utils';

export default Vue.extend({
  name: 'StudentProfile',
  components: {
    Events,
    Personal,
    Results,
  },
  data: () => ({
    studentInfo: null,
    studentData: null as any,
    activeTab: 0,
    eduLevelShorten: { ...eduLevelShorten },
    roles,
    ROLES_VIEW_NOTES,
  }),
  async mounted() {
    const { id } = this.$route.params;
    const { data } = await API.get(`v0/auth/user/${id}/`);
    this.studentInfo = data;
    // 1
    const { data: studentData } = await API.get(`v1/prosys/councillor/students/${id}/data/`);
    this.studentData = studentData;
  },
  computed: {
    role() {
      return this.$store.getters.activeTenantRole;
    },
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
});
