









































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { FieldTypes, Question } from '../../../types/types';

export default Vue.extend({
  name: 'Questionnaries',
  data() {
    return {
      question: null as Question | null,
      FieldTypes,
    };
  },
  computed: {
    ...mapGetters({
      predictors: 'getQBPredictors',
      activeQuestion: 'getActiveQBQuestion',
    }),
  },
  watch: {
    activeQuestion(newVal: Question | null) {
      if (newVal) {
        if (newVal.fields[0].type === FieldTypes.Radio) {
          const textValues = newVal.fields[0].text_value
            ? JSON.parse(newVal.fields[0].text_value as string)
            : ['', '', ''];
          const malteseTextValues = newVal.fields[0].maltese_text_value
            ? JSON.parse(newVal.fields[0].maltese_text_value as string)
            : ['', '', ''];
          this.question = {
            ...newVal,
            fields: [
              {
                ...newVal.fields[0],
                text_value: textValues,
                maltese_text_value: malteseTextValues,
              },
            ],
          };
        } else {
          this.question = newVal;
        }
      }
    },
  },
  methods: {
    saveQuestion() {
      if (this.question) {
        const { id } = this.$route.params;
        this.$store.dispatch('updateQBQuestion', { question: this.question, questionnaire_id: id });
      }
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    this.$store.dispatch('getQBQuestions', id);
    this.$store.dispatch('getQBPredictors');
  },
});
