














































import Vue from 'vue';
import API, { APIPagination } from '@/plugins/axios';
import { Event, StudentResults } from '@/types/types';
import { DataOptions } from 'vuetify';
import { overallStudents, riskLevelColor, studentResultTabColumn } from '../../../../utils/studentTable';
import EventDetails from '@/components/EventDetails.vue';

export default Vue.extend({
  components: { EventDetails },
  name: 'ResultsTab',
  props: ['id', 'activeTab'],
  data: () => ({
    headers: [...studentResultTabColumn],
    riskLevelColor: { ...riskLevelColor },
    tableItems: [] as StudentResults[],
    itemsCount: 0,
    loading: false,
    options: {} as DataOptions,
    dialog: false,
    note: null as Event | null,
  }),
  computed: {
    computedTableItems() {
      return overallStudents(this.tableItems);
    },
    translatedHeaders() {
      return this.headers
        .filter(h => h.value !== '_class')
        .map(h => ({ ...h, text: this.$t(`Students.Selection.${h.value}`) }));
    },
    activeTenant() {
      return this.$store.getters.activeTenant;
    },
    role() {
      return this.$store.getters.activeTenantRole;
    },
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  watch: {
    activeTab(value: number) {
      if (value === 1) {
        this.note = null;
        this.options = {} as DataOptions;
        this.fetchTableResults();
      }
    },
  },
  methods: {
    async fetchTableResults() {
      if (!(this as any).activeTenant) return;

      const params = {
        selection: {
          demographics: ['_class', 'school_year'],
          psychometrics: ['questionnaires', 'psychometric_risk', 'estimated_risk'],
        },
        filter: {
          demographics: {},
          psychometrics: {},
        },
        order_by: {
          field: 'name',
          descending: false,
        },
      };

      (this as any).loading = true;

      try {
        // 1
        const { data } = await API.post<APIPagination<StudentResults[]>>(
          `v1/prosys/councillor/students/results/${(this as any).$props.id}/`,
          params
        );
        (this as any).tableItems = data.results;
        (this as any).itemsCount = data.count;
      } catch (e) {
        console.error(e);
      } finally {
        (this as any).loading = false;
      }
    },
    async getNote(note_id: number) {
      if (this.note?.id != note_id) {
        this.note = null;
        // 2
        const { data } = await API.get<Event>(`v1/prosys/councillor/students/notes/${note_id}/`);
        this.note = data;
        this.note.created_at = this.$date(data.created_at).format('DD-MM-YYYY');
      }
    },
  },
  async mounted() {
    (this as any).fetchTableResults();
  },
});
