








































































import Vue from 'vue';
import AzureAuth from '@/plugins/AzureAuth';
export default Vue.extend({
  name: 'LayoutHeader',
  data: () => ({
    navItems: [
      {
        title: 'Learning App',
        href: process.env.VUE_APP_LEARNING_URL,
        icon: '/icons/icon_learningapp.svg',
      },
    ],
  }),
  computed: {
    userData() {
      return this.$store.state.User.userData;
    },
    imageUrl() {
      return this.$store.state.User.userData.clusterData?.image
        ? `data:image/png;base64,${this.$store.state.User.userData.clusterData?.image}`
        : this.$store.state.User.userData.image_url;
    },
    userName() {
      let userName = '';
      let initials = '';
      if (this.$store.state.User.userData.clusterData?.givenName) {
        userName = `${this.$store.state.User.userData.clusterData?.givenName} ${this.$store.state.User.userData.clusterData?.middleName} ${this.$store.state.User.userData.clusterData?.surname}`;
        initials = `${this.$store.state.User.userData.clusterData?.givenName?.[0]} ${this.$store.state.User.userData.clusterData?.surname?.[0]}`;
      } else {
        userName = this.$store.state.User.userData.displayName;
        initials = `${this.$store.state.User.userData.first_name?.[0]} ${this.$store.state.User.userData.last_name?.[0]}`;
      }

      return [userName, initials];
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout');
      AzureAuth.logout();
    },
  },
});
