











































































































































































































import Vue from 'vue';
import { DataOptions, DataTableHeader } from 'vuetify';
import API, { APIPagination } from '@/plugins/axios';
import { Event, Predictor, Questionnaire, StudentResults } from '@/types/types';
import {
  allColumns,
  psychometricsColumns,
  displayColumnsDemographics,
  displayColumnsPsychometrics,
  overallStudents,
  ageFilter,
  riskFilter,
  riskLevelColor,
} from '@/utils/studentTable';
import {
  roles,
  ROLES_VIEW_NOTES,
  ROLES_VIEW_STUDENT_DATA,
  // ROLES_VIEW_STUDENT_RESPONSES_DEM,
  ROLES_VIEW_STUDENT_RESPONSES_PSY,
} from '@/utils/utils';
import EventDetails from '@/components/EventDetails.vue';

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();

const schoolYears: string[] = [];
for (let year = 2021; year <= currentYear; year++) {
  if (year !== currentYear || currentMonth >= 8) {
    schoolYears.push(`${year} - ${year + 1}`);
  }
}

interface Group {
  id: number | null;
  title: string;
}

export default Vue.extend({
  name: 'Students',
  components: { EventDetails },
  data() {
    return {
      represented: 'Questionnaire',
      riskLevelColor: { ...riskLevelColor },
      showInTable: {
        demographics: { ...displayColumnsDemographics },
        psychometrics: { ...displayColumnsPsychometrics },
      },
      filterOptions: {
        _class: ['I', 'II', 'III', 'IV'],
        gender: [
          this.$t('Students.Filters.male'),
          this.$t('Students.Filters.female'),
          this.$t('Students.Filters.prefer_not_to_say'),
        ],
        age: Object.keys(ageFilter),
        school_year: schoolYears,
        disability: [this.$t('Students.Filters.yes'), this.$t('Students.Filters.no')],
        employed: [this.$t('Students.Filters.yes'), this.$t('Students.Filters.no')],
        education_level: [
          this.$t('Students.Filters.no_formal_qualification'),
          this.$t('Students.Filters.dont_know'),
          this.$t('Students.Filters.school_leaving_certificate'),
          this.$t('Students.Filters.mqf3'),
          this.$t('Students.Filters.mqf4'),
          this.$t('Students.Filters.mqf5'),
          this.$t('Students.Filters.mqf6'),
          this.$t('Students.Filters.mqf7'),
          this.$t('Students.Filters.mqf8'),
          this.$t('Students.Filters.prefer_not_to_say'),
        ],
        lives_with_parents: [this.$t('Students.Filters.yes'), this.$t('Students.Filters.no')],
        dependants: [this.$t('Students.Filters.yes'), this.$t('Students.Filters.no')],
        income: [
          this.$t('Students.Filters.no_income'),
          this.$t('Students.Filters.below10'),
          this.$t('Students.Filters.below15'),
          this.$t('Students.Filters.below20'),
          this.$t('Students.Filters.below25'),
          this.$t('Students.Filters.no_income'),
          this.$t('Students.Filters.above30'),
          this.$t('Students.Filters.prefer_not_to_say'),
          this.$t('Students.Filters.dont_know'),
        ],
        mother_education: [
          this.$t('Students.Filters.no_formal_qualification'),
          this.$t('Students.Filters.dont_know'),
          this.$t('Students.Filters.school_leaving_certificate'),
          this.$t('Students.Filters.mqf3'),
          this.$t('Students.Filters.mqf4'),
          this.$t('Students.Filters.mqf5'),
          this.$t('Students.Filters.mqf6'),
          this.$t('Students.Filters.mqf7'),
          this.$t('Students.Filters.mqf8'),
          this.$t('Students.Filters.prefer_not_to_say'),
        ],
        father_education: [
          this.$t('Students.Filters.no_formal_qualification'),
          this.$t('Students.Filters.dont_know'),
          this.$t('Students.Filters.school_leaving_certificate'),
          this.$t('Students.Filters.mqf3'),
          this.$t('Students.Filters.mqf4'),
          this.$t('Students.Filters.mqf5'),
          this.$t('Students.Filters.mqf6'),
          this.$t('Students.Filters.mqf7'),
          this.$t('Students.Filters.mqf8'),
          this.$t('Students.Filters.prefer_not_to_say'),
        ],
        household_income: [
          this.$t('Students.Filters.no_income'),
          this.$t('Students.Filters.below12700'),
          this.$t('Students.Filters.below21200'),
          this.$t('Students.Filters.below28700'),
          this.$t('Students.Filters.below60'),
          this.$t('Students.Filters.above60'),
          this.$t('Students.Filters.prefer_not_to_say'),
          this.$t('Students.Filters.dont_know'),
        ],
        predictor_title: [] as string[],
        predictor_risk: [
          this.$t('Students.Filters.none'),
          this.$t('Students.Filters.yes'),
          this.$t('Students.Filters.no'),
        ],
        predictor_score: null,
        questionnaire_title: [] as string[],
        questionnaire_risk: [this.$t('Students.Filters.yes'), this.$t('Students.Filters.no')],
        questionnaire_score: null,
        psychometric_risk: [
          this.$t('Students.Filters.no_to_low_risk'),
          this.$t('Students.Filters.medium_risk'),
          this.$t('Students.Filters.high_risk'),
        ],
        psychometric_score: null,
        estimated_risk: [
          this.$t('Students.Filters.none'),
          this.$t('Students.Filters.no_to_low_risk'),
          this.$t('Students.Filters.medium_risk'),
          this.$t('Students.Filters.high_risk'),
        ],
        note: null,
      },
      filters: {
        _class: [],
        gender: [],
        age: [],
        school_year: [schoolYears[schoolYears.length - 1]],
        disability: [],
        employed: [],
        education_level: [],
        lives_with_parents: [],
        dependants: [],
        income: [],
        mother_education: [],
        father_education: [],
        household_income: [],
        predictor_title: [],
        predictor_risk: [],
        predictor_score: [],
        questionnaire_title: [],
        questionnaire_risk: [],
        questionnaire_score: [],
        psychometric_risk: [],
        psychometric_score: [],
        estimated_risk: [],
        note: [],
      },
      students: [] as StudentResults[],
      studentsCount: 0,
      loading: false,
      options: {} as DataOptions,
      filterName: '',
      filterNameTimeout: null as null | number,
      dialog: false,
      note: null as Event | null,
      groups: [] as Group[],
      selectedGroup: null as number | null,
      roles,
      ROLES_VIEW_STUDENT_DATA,
      // ROLES_VIEW_STUDENT_RESPONSES_DEM,
      ROLES_VIEW_STUDENT_RESPONSES_PSY,
      currentActiveState: null as null | string,
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchStudents();
      },
      deep: true,
    },
    filters: {
      handler() {
        this.resetPage();
        this.fetchStudents();
      },
      deep: true,
    },
    selectedGroup: {
      handler() {
        this.resetPage();
        this.fetchStudents();
      },
      deep: true,
    },
    showInTable: {
      handler() {
        this.resetPage();
        this.clearFilters();
        this.fetchStudents();
      },
      deep: true,
    },
    filterName() {
      if (this.filterNameTimeout) {
        window.clearTimeout(this.filterNameTimeout);
      }
      this.filterNameTimeout = window.setTimeout(() => {
        this.resetPage();
        this.fetchStudents();
      }, 500);
    },
    activeTenant() {
      this.fetchStudents();
    },
    currentActiveState: {
      handler() {
        this.resetPage();
        this.fetchStudents();
      },
      deep: true,
    },
  },
  computed: {
    headers() {
      let headers = [...allColumns];
      headers = headers.reduce((acc: DataTableHeader[], cur) => {
        if (Object.keys(this.showInTable.demographics).includes(cur.value)) {
          if ((this.showInTable.demographics as { [key: string]: boolean })[cur.value]) return [...acc, cur];
          return acc;
        } else if (Object.keys(this.showInTable.psychometrics).includes(cur.value)) {
          if ((this.showInTable.psychometrics as { [key: string]: boolean })[cur.value]) {
            if (cur.value === 'predictors' || cur.value === 'questionnaires') {
              if (acc.find(h => h.value === 'questionnaire_title')) {
                return [
                  ...acc,
                  ...(psychometricsColumns as { [key: string]: DataTableHeader[] })[cur.value].filter(
                    h => h.value !== 'questionnaire_title'
                  ),
                ];
              }
            }
            return [...acc, ...(psychometricsColumns as { [key: string]: DataTableHeader[] })[cur.value]];
          }
          return acc;
        } else {
          return [...acc, cur];
        }
      }, []);
      headers = headers.filter(h => {
        if (h.value === '_class') return false;
        if (h.value === 'note' && !ROLES_VIEW_NOTES.includes(this.role)) {
          return false;
        }
        if (
          [
            'questionnaire_title',
            'questionnaire_risk',
            'questionnaire_score',
            'predictor_title',
            'predictor_risk',
            'predictor_score',
            'psychometric_risk',
            'psychometric_score',
            'estimated_risk',
          ].includes(h.value) &&
          !ROLES_VIEW_STUDENT_RESPONSES_PSY.includes(this.role)
        ) {
          return false;
        }
        return true;
      });
      return headers.map(h => ({ ...h, text: this.$t(`Students.Selection.${h.value}`) }));
    },
    computedFilters() {
      return Object.keys((this as any).filterOptions).filter(filter => {
        if (filter === '_class') return false;
        if (
          !ROLES_VIEW_STUDENT_RESPONSES_PSY.includes(this.role) &&
          [
            'questionnaire_title',
            'questionnaire_risk',
            'questionnaire_score',
            'predictor_title',
            'predictor_risk',
            'predictor_score',
            'psychometric_risk',
            'psychometric_score',
            'estimated_risk',
          ].includes(filter)
        )
          return false;
        if (!ROLES_VIEW_NOTES.includes(this.role) && filter === 'note') return false;
        if (Object.keys((this as any).showInTable.demographics).includes(filter)) {
          return ((this as any).showInTable.demographics as { [key: string]: boolean })[filter];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } else if (Object.keys((this as any).additionalColumnsPsychometrics).includes(filter)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return ((this as any).additionalColumnsPsychometrics as { [key: string]: boolean })[filter];
        } else {
          return true;
        }
      });
    },
    sortedFilters() {
      const filters = Object.entries((this as any).filters).reduce((acc: { [key: string]: string[] }, [key, value]) => {
        acc[key] = [...(value as any)].sort(
          (a, b) => (this.filterOptions as any)[key].indexOf(a) - (this.filterOptions as any)[key].indexOf(b)
        );
        return acc;
      }, {});
      return { ...filters };
    },
    additionalColumnsPsychometrics() {
      return {
        predictor_title: (this as any).showInTable.psychometrics.predictors,
        predictor_risk: (this as any).showInTable.psychometrics.predictors,
        predictor_score: (this as any).showInTable.psychometrics.predictors,
        questionnaire_title:
          (this as any).showInTable.psychometrics.predictors || (this as any).showInTable.psychometrics.questionnaires,
        questionnaire_risk: (this as any).showInTable.psychometrics.questionnaires,
        questionnaire_score: (this as any).showInTable.psychometrics.questionnaires,
        psychometric_risk: (this as any).showInTable.psychometrics.psychometric_risk,
        psychometric_score: (this as any).showInTable.psychometrics.psychometric_risk,
        estimated_risk: (this as any).showInTable.psychometrics.estimated_risk,
        note: (this as any).showInTable.psychometrics.estimated_risk,
      };
    },
    computedStudents() {
      return overallStudents((this as any).students);
    },
    activeTenant() {
      return this.$store.getters.activeTenant;
    },
    role() {
      return this.$store.getters.activeTenantRole;
    },
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  methods: {
    async fetchStudents(as_csv = false) {
      if (!this.activeTenant) return;

      interface Params {
        selection: {
          demographics: string[];
          psychometrics: string[];
        };
        filter: {
          demographics: {
            [key: string]: string | string[];
          };
          psychometrics: {
            [key: string]: string | string[];
          };
        };
        order_by?: {
          field: string;
          descending: boolean;
        };
        classter_group_id?: string;
      }

      this.loading = true;

      const filters = Object.keys(this.filters).filter(f => (this.filters as { [key: string]: string[] })[f].length);
      const demographicSelection = Object.entries(this.showInTable.demographics)
        .filter(([_, value]: [string, boolean]) => !!value)
        .map(([key]) => key);
      const psychometricsSelection = Object.entries(this.showInTable.psychometrics)
        .filter(([_, value]: [string, boolean]) => !!value)
        .map(([key]) => key);
      const demographicFilter = Object.fromEntries(
        filters
          .filter(f => Object.keys(this.showInTable.demographics).includes(f))
          .map(f => [f, (this.filters as { [key: string]: string | string[] })[f]])
      );
      if (this.filterName) demographicFilter.name = this.filterName;
      if (demographicFilter.age) {
        demographicFilter.age = (demographicFilter.age as string[]).map(
          age => (ageFilter as Record<string, string>)[age]
        );
      }
      const psychometricsFilter = Object.fromEntries(
        filters
          .filter(f => Object.keys(this.additionalColumnsPsychometrics).includes(f))
          .map(f => [f, (this.filters as { [key: string]: string | string[] })[f]])
      );

      if (psychometricsFilter.psychometric_risk) {
        psychometricsFilter.psychometric_risk = (psychometricsFilter.psychometric_risk as string[]).map(
          risk => (riskFilter as Record<string, string>)[risk]
        );
      }

      if (psychometricsFilter.estimated_risk) {
        psychometricsFilter.estimated_risk = (psychometricsFilter.estimated_risk as string[]).map(
          risk => (riskFilter as Record<string, string>)[risk]
        );
      }

      if (this.currentActiveState !== null) {
        psychometricsFilter.is_active = [this.currentActiveState];
      }

      const params: Params = {
        selection: {
          demographics: demographicSelection,
          psychometrics: psychometricsSelection,
        },
        filter: {
          demographics: demographicFilter,
          psychometrics: psychometricsFilter,
        },
        order_by: {
          field: this.options.sortBy[0] || 'name',
          descending: this.options.sortDesc[0] || false,
        },
      };

      if (as_csv) {
        (params as any).as_csv = true;
      }

      if (this.role == roles.STUDENT_MENTOR && this.selectedGroup) {
        params.classter_group_id = `${this.selectedGroup}`;
      }

      try {
        if (!as_csv) {
          // 1
          const { data } = await API.post<APIPagination<StudentResults[]>>(
            `v1/prosys/councillor/students/list/?page=${this.options.page}`,
            params
          );
          this.students = data.results;
          this.studentsCount = data.count;
        } else {
          // 1
          const { data } = await API.post<APIPagination<StudentResults[]>>(
            `v1/prosys/councillor/students/list/csv/`,
            params
          );
          const blob = new Blob([data.results as any], { type: 'text/csv;charset=utf-8;' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', 'students.csv');
          link.click();
          URL.revokeObjectURL(url);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    clearFilters() {
      Object.keys(this.showInTable.demographics).forEach(key => {
        if (!(this.showInTable.demographics as { [key: string]: boolean })[key]) {
          (this.filters as { [key: string]: string[] })[key] = [];
        }
      });
      Object.keys(this.showInTable.psychometrics).forEach(key => {
        if (!(this.showInTable.psychometrics as { [key: string]: boolean })[key]) {
          (this.filters as { [key: string]: string[] })[key] = [];
        }
      });
    },
    clearAllFilters() {
      Object.keys(this.filters).forEach(key => {
        (this.filters as { [key: string]: string[] })[key] = [];
      });
      this.filterName = '';
    },
    clearAllSelections() {
      Object.keys(this.showInTable.psychometrics).forEach(key => {
        (this.showInTable.psychometrics as { [key: string]: boolean })[key] = false;
      });
      Object.keys(this.showInTable.demographics).forEach(key => {
        (this.showInTable.demographics as { [key: string]: boolean })[key] = false;
      });
    },
    resetPage() {
      this.options.page = 1;
    },
    getLabelText(label: string) {
      return this.$t(`Students.Selection.${label}`);
    },
    async getNote(note_id: number) {
      if (this.note?.id != note_id) {
        this.note = null;
        // 2
        const { data } = await API.get<Event>(`v1/prosys/councillor/students/notes/${note_id}/`);
        this.note = data;
        this.note.created_at = this.$date(data.created_at).format('DD-MM-YYYY');
      }
    },
    async getPredictors() {
      try {
        // 1
        const { data } = await API.get<Predictor[]>(`v1/prosys/councillor/predictors/`);
        this.filterOptions.predictor_title = data.map(p => p.name);
      } catch (e) {
        console.error(e);
      }
    },
    async getQuestionnaires() {
      try {
        // 1
        const { data } = await API.get<Questionnaire[]>(`v1/prosys/councillor/questionnaires/`);
        this.filterOptions.questionnaire_title = [...new Set(data.map(q => q.title))];
      } catch (e) {
        console.error(e);
      }
    },
    async getGroups() {
      try {
        if (this.role !== roles.STUDENT_MENTOR) return;
        const { data } = await API.get<Group[]>('v0/analytics/groups/');
        this.groups = [{ id: null, title: 'All' }, ...data];
      } catch (e) {
        console.error(e);
      }
    },
  },
  async mounted() {
    await this.getGroups();
    await this.getPredictors();
    await this.getQuestionnaires();
  },
});
