




































































































































































import Vue from 'vue';
import { convertToPsychometrics2, GroupAnswer, Psychometrics2Question } from './types';
import API from '../../../plugins/axios';
import { FieldTypes, Questionnaire } from '../../../types/types';

export default Vue.extend({
  props: ['id', 'user_id'],
  data() {
    return {
      selectedAnswer: 0,
      finalEstimate: {} as { [key: string]: { value: number; disabled: boolean } },
      additionalNote: '',
      hadAdditionalNote: false,
      dialog: false,
      loadingNext: false,
      is_changed: false,
    };
  },
  computed: {
    questions(): Psychometrics2Question[] {
      return this.$store.getters.questions;
    },
    questionNumber(): number {
      return this.$store.getters.questionNumber;
    },
    question(): Psychometrics2Question {
      return this.activeQuestions[this.questionNumber];
    },
    activeQuestions(): Psychometrics2Question[] {
      return this.questions
        .filter(question => question.predictor_name === this.activePredictor)
        .sort((a, b) => a.order - b.order);
    },
    predictors(): string[] {
      return this.$store.getters.predictors;
    },
    predictorNumber(): number {
      return this.$store.getters.predictorNumber;
    },
    activePredictor(): string {
      return this.$store.getters.activePredictor;
    },
    predictorEstimate: {
      get(): number {
        return this.finalEstimate[this.activePredictor]?.value || 0;
      },
      set(val: number) {
        if (this.finalEstimate[this.activePredictor]) this.finalEstimate[this.activePredictor].value = val;
      },
    },
    bgColor(): string {
      return this.$store.state.User.settings.common.background_color || 'default';
    },
  },
  watch: {
    activePredictor() {
      this.selectedAnswer = this.question?.selected_answer || 0;
    },
    questionNumber() {
      this.selectedAnswer = this.question?.selected_answer || 0;
      window.scrollTo(0, 0);
    },
    async predictorEstimate(curr) {
      const group_id = this.activeQuestions[0]?.answer_group_id;
      if (!group_id) return;
      this.setRisk(curr, group_id);
    },
    finalEstimate: {
      async handler(val) {
        // 3
        const { data: groupAnswers } = await API.get(
          `v1/prosys/councillor/answer_groups/evaluations/${this.$route.params.id}/`
        );
        const changed = groupAnswers
          .filter((x: GroupAnswer) => {
            let risk;
            if (!x.is_populated) risk = 2;
            else if (x.risk) risk = 0;
            else risk = 1;
            return val[x.predictor].value !== risk;
          })
          .map((x: GroupAnswer) => ({ [x.predictor]: val[x.predictor].value }));
        changed.forEach(async (e: any) => {
          const p = Object.keys(e)[0];
          const v = e[p];
          const group_id = this.questions.find(q => q.predictor_name === p)?.answer_group_id;
          if (!group_id) return;
          this.setRisk(v, group_id);
        });
      },
      deep: true,
    },
  },
  methods: {
    handlePrev() {
      if (this.questionNumber > 0 || this.predictorNumber > 0) {
        let payload;
        if (this.questionNumber > 0) {
          payload = {
            questionNumber: this.questionNumber - 1,
          };
        } else {
          const predictorNumber = this.predictorNumber - 1;
          const questionNumber =
            this.questions.filter(question => question.predictor_name === this.predictors[predictorNumber]).length - 1;
          payload = {
            questionNumber,
            predictorNumber,
          };
        }
        this.$store.dispatch('updateQuestionsState', payload);
      }
    },
    async handleNext() {
      this.dialog = false;
      this.loadingNext = true;
      if (this.question && this.question.selected_answer !== this.selectedAnswer) {
        this.is_changed = true;
      }
      if (this.questionNumber < this.activeQuestions.length) {
        const value = this.question.field_type === FieldTypes.Radio ? this.selectedAnswer : null;
        const text_value = this.question.field_type === FieldTypes.Radio ? null : this.question.answer;
        // 3
        await API.post(`v1/prosys/councillor/answers/${this.$route.params.user_id}/${this.$route.params.id}/`, {
          question: this.question.id,
          evaluation: this.$route.params.id,
          fields: [
            {
              value,
              text_value,
              question_field_id: this.question.field_id,
            },
          ],
        });
        this.question.selected_answer = this.selectedAnswer;
        this.$store.dispatch('updateQuestionsState', {
          questionNumber: this.questionNumber + 1,
        });
        if (this.is_changed && this.questionNumber === this.activeQuestions.length) {
          let risk = 1;
          for (const question of this.activeQuestions) {
            const answer = question.answers?.[question.selected_answer ?? 0];
            if (answer?.value !== 'Low') {
              risk = 0;
              break;
            }
          }
          this.predictorEstimate = risk;
          this.is_changed = false;
        }
      } else if (this.questionNumber === this.activeQuestions.length) {
        if (this.predictors.indexOf(this.activePredictor) === this.predictors.length - 1) {
          this.$store.dispatch('updateQuestionsState', {
            questionNumber: -1,
            predictorNumber: this.predictors.length,
          });
        } else {
          this.$store.dispatch('updateQuestionsState', {
            questionNumber: 0,
            predictorNumber: this.predictorNumber + 1,
          });
        }
        this.predictorEstimate = 0;
      }
      this.loadingNext = false;
    },
    async setRisk(value: number, group_id: number) {
      if (value === 2) {
        // 3
        await API.delete(`v1/prosys/councillor/answer_groups/${group_id}/`);
      } else {
        const risk = value === 0 ? true : false;
        // 3
        await API.post(`v1/prosys/councillor/answer_groups/${group_id}/`, { risk });
      }
    },
    handleEdit(val: string) {
      this.finalEstimate[val].disabled = !this.finalEstimate[val].disabled;
    },
    async handleFinish() {
      if (this.additionalNote || this.hadAdditionalNote) {
        // 3
        await API.post(`v1/prosys/councillor/evaluation/${this.$route.params.id}/`, {
          additional_note: this.additionalNote,
        });
      }
      // 3
      await API.put(`v1/prosys/councillor/evaluations/finish/${this.$route.params.user_id}/${this.$route.params.id}/`);
      this.$router.push(`/creator/students/${this.$route.params.user_id}`);
    },
  },
  async mounted() {
    // 3
    const { data } = await API.get(
      `v1/prosys/councillor/questions/${this.$route.params.user_id}/${this.$route.params.id}/`
    );
    const questions: Psychometrics2Question[] = data.map(convertToPsychometrics2);
    const predictors = Array.from(new Set(questions.map(question => question.predictor_name)));
    let predictorNumber, firstUnanswered;
    for (const i in predictors) {
      const predictor = predictors[i];
      const filteredQuestions = questions.filter(question => question.predictor_name === predictor);
      firstUnanswered = filteredQuestions.findIndex(q => q.selected_answer === undefined && !q.answer);
      if (firstUnanswered >= 0) {
        predictorNumber = +i;
        break;
      }
    }
    // 3
    const { data: groupAnswers } = await API.get(
      `v1/prosys/councillor/answer_groups/evaluations/${this.$route.params.id}/`
    );
    this.finalEstimate = (groupAnswers as GroupAnswer[]).reduce((acc, cur) => {
      let value;
      if (!cur.is_populated) value = 2;
      else value = cur.risk ? 0 : 1;
      acc[cur.predictor] = {
        value,
        disabled: true,
      };
      return acc;
    }, {} as { [key: string]: { value: number; disabled: boolean } });
    this.$store.dispatch('updateQuestionsState', {
      questionNumber: firstUnanswered,
      questionsLength: data.length,
      finished: false,
      closing: false,
      isCompleted: false,
      questions,
      predictors,
      predictorNumber,
      groupAnswers,
    });
    // 3
    const { data: questionnaires } = await API.get<Questionnaire[]>(
      `v1/prosys/councillor/students/${this.$route.params.user_id}/`
    );
    // find current evaluation
    let evaluation = {} as Questionnaire['evaluation'][0];
    for (const questionnaire in questionnaires) {
      if (questionnaires[questionnaire].evaluation.length) {
        for (const ev in questionnaires[questionnaire].evaluation) {
          if (questionnaires[questionnaire].evaluation[ev].id == Number(this.$route.params.id)) {
            evaluation = questionnaires[questionnaire].evaluation[ev];
            break;
          }
        }
      }
    }
    this.additionalNote = evaluation?.additional_note || '';
    this.hadAdditionalNote = !!this.additionalNote;
  },
});
