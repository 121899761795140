
































import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      items: [
        { text: this.$t('Psychometrics2.questionnaires'), disabled: true },
        { text: this.$t('Psychometrics2.psychometrics2'), disabled: true },
        { text: '', disabled: true },
      ],
    };
  },
  computed: {
    predictors() {
      return [this.$t('Psychometrics2.overview'), ...this.$store.getters.predictors];
    },
    activePredictor: {
      get() {
        return this.$store.getters.activePredictor || this.$t('Psychometrics2.overview');
      },
      set(val: string) {
        if (val === this.$t('Psychometrics2.overview')) {
          this.$store.dispatch('updateQuestionsState', {
            questionNumber: -1,
            predictorNumber: this.predictors.length,
          });
        } else {
          this.$store.dispatch('changeActivePredictor', val);
        }
      },
    },
    questionNumber() {
      return this.$store.getters.questionNumber;
    },
    activeQuestionsLength() {
      return this.$store.getters.activeQuestionsLength;
    },
  },
  methods: {
    finish() {
      this.$store.dispatch('updateQuestionsState', {
        questionNumber: -1,
        predictorNumber: this.predictors.length,
      });
    },
  },
});
