import { render, staticRenderFns } from "./LayoutSidebar.vue?vue&type=template&id=d3c04222&scoped=true&"
import script from "./LayoutSidebar.vue?vue&type=script&lang=ts&"
export * from "./LayoutSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./LayoutSidebar.vue?vue&type=style&index=0&id=d3c04222&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3c04222",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VAvatar,VIcon,VList,VListItem,VNavigationDrawer})
